.booking-v1 {

	.title {
		background-color: var(--color-bg-light);
		padding-left: 10px;
		font-weight: 700;
		font-size: var(--h4-font-size);
	}

	.info-box {
		border: 1px solid var(--color-grey-dark);
		padding: 10px;
		border-radius: var(--border-radius);
		margin-bottom: 30px;

		.booking-data {
			flex-wrap: wrap;
			display: flex;

			.data {
				min-width: 33%;
				position: relative;
				padding: 10px;
				border-bottom: 1px solid var(--color-grey-dark);
				text-align: center;

				@media (max-width: 560px) {
					width: 50%;
					font-size: var(--font-size-md);
				}

				.fa,
				.fas,
				.far,
				.fa-sharp {
					padding-right: 5px;
				}

			}

			.date {
				white-space: nowrap;
			}

			.label {
				display: block;
				font-size: var(--font-size-md);
			}
		}

		.booking-data::after {
			content: "";
			width: 100%;
			height: 1px;
			background-color: var(--color-grey-dark);
			margin-top: -1px;
			/* Abstand zwischen der Linie und den Zeilen */
		}

		.button-line {
			text-align: center;
			padding-top: 15px;
			margin: 0px;
		}
	}

	.booking-view {
		/*padding-top: 1px;
		margin-top: var(--page-margin-top);*/

	}

	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}

	.label {
		display: inline-block;
	}

	.unit-section {

		border: 1px solid var(--color-grey-dark);
		padding: 10px;
		border-radius: var(--border-radius);

		.fa,
		.fas,
		.far,
		.fa-sharp {
			padding-right: 5px;
		}

		.unit-name {
			font-size: 20px;
		}

		.address {
			padding-top: 10px;
		}
	}

	.price-section {
		margin-top: 30px;
		border: 1px solid var(--color-grey-dark);
		padding: 10px;
		border-radius: var(--border-radius);

	}

	.urv-section {
		margin-top: 30px;
		border: 1px solid var(--color-grey-dark);
		padding: 10px;
		border-radius: var(--border-radius);

	}

	.urv-result {
		border: 1px solid var(--color-grey-dark);
		padding: 10px;
		border-radius: var(--border-radius);

		.lead {
			font-weight: 700;
		}

	}

	.properties {
		margin-top: 15px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		div {
			min-width: 50%;
			font-size: var(--font-size-md);
		}

		.fa-stack {
			width: 1.5em;
		}
	}

	.personal-data {
		border: 1px solid var(--color-grey-dark);
		padding: 15px;
		margin-top: 20px;
		border-radius: var(--border-radius);
	}

	.pd-form {
		label {
			font-weight: 500;
		}

	}

	.checks {

		.checkbox {

			label {
				vertical-align: inherit;
				display: unset;
			}
		}

		a {
			color: var(--font-color-main) !important;
			text-decoration: underline;
		}
	}


	.flow-btn {
		margin-left: 20px;
	}

	.step2 {

		.info-box {
			.booking-data {
				.data {
					@media (min-width:561px) {
						min-width: 33%;
					}

					@media (max-width:560px) {
						min-width: 50%;
					}
				}
			}
		}

		@media(min-width:992px) {
			.col-lg-12 {
				&.unit-picture {
					width: 50% !important;
				}
			}

			.col-lg-12 {
				&.unit-info {
					width: 50% !important;
				}
			}
		}

		@media(min-width:1200px) {

			.col-xl-12 {
				&.unit-picture {
					width: 33.33333333% !important;
				}
			}

			.col-xl-12 {
				&.unit-info {
					width: 66.66666667% !important;
				}
			}
		}


	}

	.amount-options-list {
		margin: 20px 0;

		.amount-option-btn {
			.amount-or {
				text-align: center;
				margin: 15px 0;
				width: 100%;
				max-width: 305px;
			}

			.btn-default {
				width: 100%;
				max-width: 305px;
			}
		}

	}

	.coupon-input {
		margin-right: 15px;

		@media(max-width: 576px) {
			margin-right: 10px;
		}
	}

	.alert-short-booking {
		margin-top: 15px;
	}

}