.hero-image-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media(max-height:500px) {
		min-height: 500px;
	}

	.picture {
		height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);

		@media(max-height:500px) {
			min-height: 500px;
		}

		.img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.hero-header {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media(max-width:992px) AND (min-height: 701px) {
			bottom: 45vh;
		}

		@media(max-width:992px) AND (max-height: 700px) {
			top: 10vh;
		}

		@media(min-width:999px) AND (min-height: 501px) {
			bottom: 29vh;
		}

		@media(min-width:992px) AND (max-height: 500px) {
			top: 30vh;
		}



		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
				margin-bottom: 1rem;
			}

			.h1 {
				font-family: var(--hero-header-font-family);
				font-size: 40px;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 50px;
				}

				@media (max-width: 1140px) {
					font-size: 40px;
				}

				@media (max-width: 992px) {
					font-size: 30px;
					line-height: 30px;
				}

				@media (max-width: 576px) {
					/*font-size: 3rem;
					line-height: 3rem;*/
				}

				@media (max-height: 600px) {
					font-size: 2.75rem;
					line-height: 2.75rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);

				color: #fff;
				/*text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);*/
				background: url(RESOURCE/img/banderole_big.png) no-repeat 0 0;
				background-position: center;
				background-size: contain;
				font-size: 1.3rem;
				margin-top: auto;
				text-transform: none;
				max-width: 600px;
				height: 61px;
				padding: 10px 4% 0;
				display: table;
				margin: 0 auto;

				/*	@media (min-width: 1600px) {
					font-size: 2rem;
				}*/

				/*@media (max-width: 1140px) {
					font-size: 2.0rem;
				}*/

				/*@media (max-width: 992px) {
					font-size: 1.5rem;
				}*/

				@media (max-width: 575px) {
					font-size: 16px;
					width: 100%;
					padding-top: 11px;
				}
			}
		}

	}
}