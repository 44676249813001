.footer-v1 {

	.main-footer {
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.v-office-logo {

			background-color: var(--color-secondary);
			padding: 10px 20px;

			@media(min-width: 993px) {
				text-align: right;
				padding: 10px 60px;
			}

			@media(max-width: 992px) {
				text-align: left;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}

			.btn-secondary {
				&:hover {
					background: var(--btn-secondary-border-color-hover);
				}
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);

					@media(max-width: 767px) {
						word-break: break-all;
					}
				}

				.fa,
				.fa-brands {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa,
			.fa-brands {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: var(--color-white);
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa,
			.fa-brands {
				color: var(--color-white);
				font-size: 1.8rem;
				padding-left: 10px;

			}
		}
	}

	.social-icons {
		.list-inline-item {
			display: flex;
			//word-break: break-all;

			i {
				margin-right: 10px;
			}
		}
	}

}