.static-view {
	.cta-bl {
		display: flex;
		align-items: center;
		justify-content: space-around;
		background: #f7f7f7;
		padding: 30px;
		margin: 30px 0;
	}

	a {
		font-weight: 500;
	}
}